import React from "react";
import { Button, Card, CardBody, Row, Col } from "reactstrap";
import { Consts } from "config/consts";
import { ClipLoader } from "react-spinners";
import Switch, { Case, Default } from "react-switch-case";
import { AuthenticationContext, Authentication } from "context/authentication";
import { getWebAppValue, decodeRule, encodeRule } from "config/utilities"
import { DriveRules } from "components/home/rules/drive-rules";
import { LicenseAgreement } from "components/dialogs/license-agreement";
import { Installer } from "components/dialogs/installer";
import { GettingStarted } from "components/dialogs/getting-started";
import { Link } from 'react-router-dom'
import { Firestore } from "config/firebase";
import { loadStripe } from '@stripe/stripe-js';
import { Elements, ElementsConsumer } from '@stripe/react-stripe-js';
import { StripePayment } from "components/dialogs/stripe-payment";
import { AddLicenseKey } from "components/dialogs/add-license-key";
import { ManageLicenses } from "components/dialogs/manage-licenses";
import { updateSystemPublic } from "config/firebase";
import { Redirect } from "react-router-dom";

const dialogsHidden = 0;
const dialogLicenseAgreement = 1;
const dialogInstaller = 2;
const dialogGettingStarted = 3;
const dialogStripe = 4;
const dialogAddLicenseKey = 5;
const dialogManageLicenses = 6;

const modeWaiting = 0;
const modeInstall = 1;
const modeUpgrade = 2;
const modeServiceError = 3;
const modeNormal = 10;

const upgradeNone = 0;
const upgradeAvailable = 1;
const upgradeRequired = 2;
const upgradeForce = 3;

const launchCommandLine = 1;
const launchLoggingConsole = 2;

const stripePromise = loadStripe(Consts.SYSTEM.RELEASE ? Consts.STRIPE.REL.API_KEY : Consts.STRIPE.DEV.API_KEY);

class Home extends React.Component {
  constructor(props) {
    super(props);
    this.props.setCleanPage();
    this.state = {
      authContext: null,
      installerMode: 0,
      installerVersion: "",
      manageMode: modeWaiting,
      upgradeState: this.props.upgrade ? upgradeForce : upgradeNone,
      driveList: [],
      displayDialog: 0,
      fatalError: null,
      serviceVersion: "Not detected, refresh to detect",
      licenseType: 0,
      licenseState: 0,
      licenseDetails: "Not detected, refresh to detect",
      licenseKey: "Not detected, refresh to detect",
      licenseValidated: false,
      redirectLogin: false
    };

    this.visible = false;

    this.stateValue = null;
    this.installed = false;

    this.verificationTimer = null
    this.versionTimer = null;

    this.machineKey = null;
    this.machineName = null;

    this.systemDrive = null;
    this.maxRulesPerDrive = 0;
    this.justInstalled = false;
  }

  componentDidMount() {
    this.visible = true;
    this.versionTimer = setInterval(() => {
      // check version ok
      Firestore.collection("system_core").doc("version").get().then((doc) => {
        if (doc.exists) {
          if (doc.data().webapp > Consts.SYSTEM.WEB_VERSION) {
            this.props.displayNotification(Consts.MESSAGE_TYPE.ERROR, "You seem to be running an outdated version of the web client, please refresh!")
          }
        }
      });
    }, 300000);
  }

  componentWillUnmount() {
    this.visible = false;
  }

  waitOnEvent = (method, timeout) => {
    // monitor...
    setTimeout(() => {
      method();
    }, timeout);
  }

  onAuthenticated = (authContext) => {
    // save context
    this.setState({
      authContext: authContext,
      options: authContext.options
    }, () => {
      if (window.location.href.indexOf("http://localhost:3") === -1 &&
        this.state.authContext &&
        this.state.authContext.state.email.indexOf("@division-m") !== -1) {
          console.log("Hi Anthony, updating server with version information (anthony only)");
          updateSystemPublic(
            {
              web_version_str: Consts.SYSTEM.WEB_VERSION_STR,
              web_version: Consts.SYSTEM.WEB_VERSION,
              core_version: Consts.SYSTEM.CORE_VERSION,
              core_version_str: Consts.SYSTEM.CORE_VERSION_STR,
              min_core_version: Consts.SYSTEM.MIN_CORE_VERSION,
              min_installer_version: Consts.SYSTEM.MIN_INSTALLER_VERSION
            }
          )
        }
    });
    this.initConnection();
  }

  onNotAuthenticated = (authContext) => {
    // redirect to login
    console.log("User not authenticated, leaving...")
    this.setState({ redirectLogin: true });
  }

  initConnection = () => {
    // start again...
    this.stateValue = null;
    this.setState({
      installerMode: 0,
      installerVersion: "",
      manageMode: modeWaiting,
      upgradeState: this.state.upgradeState === upgradeForce ? upgradeForce : upgradeNone,
      driveList: [],
      displayDialog: 0,
    }, () => {
      // try and connect... if fails, then try again until connected
      this.props.setLoader(true);
        window.roFolderWall.CheckServiceConnection(Consts.SYSTEM.CORE_VERSION, Consts.SYSTEM.MIN_CORE_VERSION, (tokenValid, serviceValid, upgradeState) => {

        // upgrade state maybe in forced state, in which case make sure set to required
        if (this.state.upgradeState === upgradeForce)
          upgradeState = upgradeRequired;

        console.log("Init check connection, tokenValid: %s, serviceValid: %s, upgradeState: %s", tokenValid, serviceValid, upgradeState);

        // check all ok
        if (tokenValid && serviceValid && upgradeState < upgradeRequired) {
          this.props.setLoader(false);
          console.log("ANVIL connected")

          this.setState({
            upgradeState: upgradeState
          }, () => {
            // grab drives and start...
            this.stateValueMonitor();
          })
        } else {
          // nope... what is the issue?
          this.props.setLoader(false);
          console.log("ANVIL connection not ready, waiting...")

          this.checkConnection();
        }
      });
    })
  }

  checkConnection = () => {
    window.roFolderWall.CheckServiceConnection(Consts.SYSTEM.CORE_VERSION, Consts.SYSTEM.MIN_CORE_VERSION, (tokenValid, serviceValid, upgradeState) => {
      console.log("Checking connection, tokenValid: %s, serviceValid: %s, upgradeState: %s", tokenValid, serviceValid, upgradeState);

      // if token not ready?
      if (!tokenValid) {
        // simply wait
        console.log("ANVIL token invalid, waiting");

        // check again...
        this.waitOnEvent(this.checkConnection, 500);
        return;
      }

      if (!serviceValid) {
        // no service, might need to be installed... or not running
        if (this.state.manageMode === modeWaiting) {
          this.props.displayNotification(Consts.MESSAGE_TYPE.ERROR, "ANVIL is not installed, or not running, waiting on a successful connection!")
          console.log("Service not available, start installation");
          this.setState({
            upgradeState: upgradeState,
            manageMode: modeInstall
          }, () => {
            this.state.authContext.setLicenseUpgradeAction({ title: "Purchase license", action: this.displayStripePayment });
            // check again if not installing
            if (this.state.displayDialog !== dialogInstaller)
              this.waitOnEvent(this.checkConnection, 1000);  // we are only checking again incase service starts...
            return;
          });
        } else {
          // check again if not installing
          if (this.state.displayDialog !== dialogInstaller)
            this.waitOnEvent(this.checkConnection, 1000);  // we are only checking again incase service starts...
          return;
        }
      }

      if (upgradeState >= upgradeRequired) {
        // service available, but wrong version
        this.props.displayNotification(Consts.MESSAGE_TYPE.ERROR, "ANVIL requires an upgrade to continue, waiting on a successful upgrade!")
        console.log("Service upgrade required, start upgrade");
        this.setState({
          upgradeState: upgradeState,
          manageMode: modeUpgrade
        });
        return;
      }

      if (tokenValid && serviceValid && upgradeState <= upgradeAvailable) {
        // we must be good
        console.log("ANVIL connection found, starting state monitor...")
        this.setState({
          upgradeState: upgradeState,
          manageMode: modeNormal
        }, () => {
          // grab drives and start...
          this.stateValueMonitor();
        })
        return;
      }

      // not sure... but lets check again
      this.waitOnEvent(this.checkConnection, 1000);
      return;
    });
  }

  stateValueMonitor = () => {
    // get version and license info (async)
    window.roFolderWall.GetServiceVersion((version) => {
      this.checkLicense();
      this.setState({
        serviceVersion: version
      });
    }, (error) => {
      console.log(error);
    })

    // determine who we are talking to (async)
    window.roFolderWall.GetHardwareProfile(this.state.authContext.state.uid, (machineKey, machineName, id1, id2, id3) => {
      this.machineKey = machineKey;
      this.machineName = machineName;
    });

    // start checking state
    this.checkStateValue();
  }

  checkLicense = () => {
    window.roFolderWall.GetLicense((license) => {
      console.log("Have license details: %o", license);

      // set global license
      this.props.setGlobalState("license", {type: license.licenseType.value, state: license.licenseState.value, validated: license.validated.value, key: license.licenseKey.value}, true);

      // check for error
      if ((this.state.authContext && license.licenseState.value === Consts.LICENSE.STATE.ERROR) ||
         (license.licenseType.value !== Consts.LICENSE.TYPE.ANVIL_FILE_SECURITY && license.licenseType.value !== Consts.LICENSE.TYPE.DRIVE_BENDER)) {

        if (license.licenseType.value !== Consts.LICENSE.TYPE.ANVIL_FILE_SECURITY && license.licenseType.value !== Consts.LICENSE.TYPE.DRIVE_BENDER) {
          // invalid product
          this.props.displayDialog(Consts.MESSAGE_TYPE.ERROR, "License error", "Your license does not allow the use of this portal!", () => {
            this.props.setGlobalState("invalid_application", true, true);
          });
        } else {
          // display error
          this.props.displayNotification(Consts.MESSAGE_TYPE.ERROR, license.lastMessage.value);
        }

        this.setState({
          licenseType: license.licenseType.value,
          licenseState: license.licenseState.value,
          licenseDetails: license.validated.value ? license.lastMessage.value : "License error",
          licenseKey: license.licenseKey.value,
          licenseValidated: license.validated.value
        });
      } else {
        this.setState({
          licenseType: license.licenseType.value,
          licenseState: license.licenseState.value,
          licenseDetails: license.validated.value ? license.lastMessage.value : "Waiting for license validation...",
          licenseKey: license.licenseKey.value,
          licenseValidated: license.validated.value
      });
      }

      // lets update menu
      if (this.state.authContext && license.licenseState.value < Consts.LICENSE.STATE.VALID_LICENSES) {  // no license
        this.state.authContext.setLicenseUpgradeAction({ title: "Purchase license", action: this.displayStripePayment });
      }

      // if license not validated, lets check again in a sec
      if (!license.validated.value) {
        this.waitOnEvent(this.checkLicense, 5000);
      }
    }, (error) => {
      console.log(error);
    })
  }

  checkStateValue = () => {
    // if we are not verified or there is a dialog, don't bother checking
    if (this.state.authContext && this.state.authContext.state.verified && this.state.displayDialog === dialogsHidden) {
      // check for connection
      window.roFolderWall.GetMountPointsChanged(this.stateValue, true, (changed, newStateValue) => {
        if (changed) {
          console.log("Mount points state has changed, was: %s, now: %s", this.stateValue, newStateValue);
          if (this.stateValue !== newStateValue) {
            this.stateValue = newStateValue;
            this.refreshDrives();
          }
        }

        // check again soon
        this.waitOnEvent(this.checkStateValue, 2500);
      }, () => {
        console.log("State monitor failed!")

        // start again...
        this.initConnection();
      });
    }
  }

  refreshDrives = (callbackSuccess, callbackFailure) => {
    this.props.setLoader(true);
    window.roFolderWall.GetAllMountPoints((mountPointList, stateValue) => {
      console.log("Got %s mount points, enumerating...", mountPointList.items.length)
      let drives = [];
      let key = 0;
      for (var i = 0; i < mountPointList.items.length; i++) {
        let s = mountPointList.items[i].split("|");
        let drive = {
          key: key++,
          id: "",
          rootPath: s[0],
          mountName: s[1],
          volumeLabel: s[2],
          mountType: s[3],
          rules: [],
          visible: s[3] === "VOLUME" ? true : false
        }

        // grab drive
        this.maxRulesPerDrive = 0;
        console.log("Getting mount point %s...", drive.rootPath);
        window.roFolderWall.GetMountPoint(drive.rootPath, (mountPoint, ignoreStateValue) => {
          console.log("Processing mount point %s for drive %s...", mountPoint.id.value, mountPoint.mountName.value);
          drive.id = mountPoint.id.value;
          drive.systemDrive = mountPoint.systemDrive.value;
          drive.stateValue = mountPoint.stateValue.value;
          this.maxRulesPerDrive = (mountPoint.rules.value.items.length > this.maxRulesPerDrive ? mountPoint.rules.value.items.length : this.maxRulesPerDrive);  // record max number of rules per drive
          for (var j = 0; j < mountPoint.rules.value.items.length; j++) {
            drive.rules.push(mountPoint.rules.value.items[j]);
          }
          drives.push(drive);
          if (drive.systemDrive)
            this.systemDrive = drive;

          // we done?
          if (drives.length === mountPointList.items.length) {
            // sort list
            drives.sort((a, b) => {
              if (a.mountName < b.mountName)
                return -1;
              if (a.mountName > b.mountName)
                return 1;
              return 0;
            });

            // now we need to validate all rule groups... and flag any orphaned support rules
            try {
              drives.forEach((d) => {
                d.rules.forEach((r) => {
                  let ro = decodeRule(r);
                  if (ro.metadata && ro.metadata.TYPE === Consts.RULE_TYPE.SUPPORT_RULE) {
                    // look for parent group
                    let hasParent = false;
                    d.rules.forEach((r2) => {
                      let ro2 = decodeRule(r2);
                      if (ro2.metadata && ro2.metadata.TYPE !== Consts.RULE_TYPE.SUPPORT_RULE && ro2.metadata.GROUP_ID === ro.metadata.GROUP_ID) {
                        hasParent = true;
                      }
                    })
                    // do we have a parent?
                    if (!hasParent) {
                      // update type
                      ro.metadata.TYPE = Consts.RULE_TYPE.ORPHANED_SUPPORT_RULE;
                      let rs = encodeRule(ro);
                      for (var i = 0; i < d.rules.length; i++) {
                        if (decodeRule(d.rules[i]).index === ro.index) {
                          d.rules[i] = rs;  // update
                        }
                      }
                    }
                  }
                })
              })
            } catch(err) {
              console.log("Fatal error!");
              console.log(err);
              this.setState({
                fatalError: err
              })
              this.props.displayDialog(Consts.MESSAGE_TYPE.ERROR, "ANVIL error", "A fatal error occurred while loading the system rules. The following error was reported: " + err, () => {
                this.props.displayNotification(Consts.MESSAGE_TYPE.ERROR, "An error occurred loading the drives!")
                this.props.setLoader(false);
              });
              return;
            }

            // done!
            this.stateValue = stateValue;
            this.setState({
              driveList: drives,
              manageMode: modeNormal
            }, () => {
              if (callbackSuccess)
                callbackSuccess(drives);
              this.props.displayNotification(Consts.MESSAGE_TYPE.INFO, "ANVIL drives have successfully loaded!")
              this.props.setLoader(false);

              // check for install wizard
              if (this.justInstalled && this.maxRulesPerDrive === 1) {
                this.setState({
                  displayDialog: dialogGettingStarted
                });
              }
            });
          }
        }, (error) => {
          console.log(error)
          if (callbackFailure)
            callbackFailure(error);
          this.props.displayNotification(Consts.MESSAGE_TYPE.ERROR, error.message)
          this.props.setLoader(false);
        });
      }
    }, (error) => {
      console.log(error)
      if (callbackFailure)
        callbackFailure(error);
      this.props.displayNotification(Consts.MESSAGE_TYPE.ERROR, error.message)
      this.props.setLoader(false);
      this.setState({
        serviceError: error.message,
        manageMode: modeServiceError
      });
    });
  }

  showLicenseAgreement = () => {
    this.setState({
      displayDialog: dialogLicenseAgreement
    })
  }

  closeLicenseAgreement = (agree) => {
    this.setState({
      displayDialog: agree ? dialogInstaller : dialogsHidden
    })
  }

  showInstaller = () => {
    this.setState({
      displayDialog: dialogInstaller
    })
  }

  closeInstaller = (installed) => {
    this.justInstalled = true;
    this.initConnection();
  }

  closeGettingStarted = (complete) => {
    this.justInstalled = false;
    this.initConnection();
  }

  closePayment = (paymentDetails) => {
    if (paymentDetails) {
      window.roFolderWall.SetLicense(this.state.authContext.state.uid, paymentDetails.planId, paymentDetails.licenseKey, paymentDetails.email, (license) => {
        this.setState({
          displayDialog: dialogsHidden,
          licenseDetails: license.lastMessage.value
        });

        if (this.state.authContext && license.licenseState.value > Consts.LICENSE.STATE.VALID_LICENSES) {  // standard license
          this.state.authContext.setLicenseUpgradeAction(null);
        }
      }, (error) => {
        this.props.displayDialog(Consts.MESSAGE_TYPE.ERROR, "License error", "There was an error setting the license, error: " + error.message);
        console.log(error);
        this.setState({
          displayDialog: dialogsHidden
        })
      });
    } else {
      this.setState({
        displayDialog: dialogsHidden
      })
    }
  }

  closeAddLicenseKey = (keyAdded) => {
    if (keyAdded) {
      window.roFolderWall.SetLicense(this.state.authContext.state.uid, "", keyAdded, "", (license) => {
        this.setState({
          displayDialog: dialogsHidden,
        }, this.checkLicense());
      }, (error) => {
        this.props.displayDialog(Consts.MESSAGE_TYPE.ERROR, "License error", "There was an error setting the license, error: " + error.message);
        console.log(error);
      });
    } else {
      this.setState({
        displayDialog: dialogsHidden
      })
    }
  }

  closeManageLicense = (action) => {
    if (action) {
      this.setState({
        displayDialog: action
      })
    } else {
      this.setState({
        displayDialog: dialogsHidden
      })
    }
  }

  handleSelect = event => {
    this.setState({ [event.target.name]: event.target.value });
  };

  resendVerificationEmail = (authContext) => {
    authContext.sendAccountVerificationEmail();
    this.props.displayNotification(Consts.MESSAGE_TYPE.INFO, "Verification email resent, check your inbox!")
  }

  setStateValue = (stateValue) => {
    if (this.stateValue !== stateValue)
      this.checkStateValue();
  }

  runInstaller = (authContext) => {
    if (getWebAppValue(Consts.WEB_APP_VALUE.INSTALL_ENABLED)) {
      this.showLicenseAgreement();
    } else {
      this.props.displayNotification(Consts.MESSAGE_TYPE.INFO, "The ANVIL installer is in maintenance mode and currently unavailable.")
      setTimeout(() => {
        this.props.displayNotification(Consts.MESSAGE_TYPE.INFO, "We'll be back online very soon!")
      }, 5000);
    }
  }

  filterDrives = (mountName) => {
    this.props.setGlobalState("driveFilter", mountName);
  }

  toggleAdvancedView = () => {
    this.props.setGlobalState("advancedView", !this.props.getGlobalState().advancedView);
  }

  noVerification = () => {
    if (this.verificationTimer)
      clearInterval(this.verificationTimer);
  }

  refreshView = () => {
    this.refreshDrives();
    this.props.displayNotification(Consts.MESSAGE_TYPE.INFO, "Refreshing view, please wait")
  }

  getClientVersion = () => {
    return "v" + Consts.SYSTEM.WEB_VERSION_STR;
  }

  launchCommandLine = () => {
    window.roFolderWall.LaunchApplication(launchCommandLine, '', () => {
      this.props.displayNotification(Consts.MESSAGE_TYPE.INFO, "Terminal launched, check for UAC prompt!")
    }, (error) => {
      this.props.displayNotification(Consts.MESSAGE_TYPE.ERROR, "Terminal failed to launch with an error!")
    });
  }

  launchLoggingConsole = () => {
    window.roFolderWall.LaunchApplication(launchLoggingConsole, '', () => {
      this.props.displayNotification(Consts.MESSAGE_TYPE.INFO, "Logging console launched!")
    }, (error) => {
      this.props.displayNotification(Consts.MESSAGE_TYPE.ERROR, "Logging console failed to launch with an error!")
    });
  }

  getInstaller = () => {
    // check for upgrade (todo)
    if (this.state.upgradeState === upgradeAvailable) {
      return (
        <React.Fragment>
          &nbsp;(<Link to="" className="text-secondary" style={{ textDecoration: "underline" }} onClick={(e) => {e.preventDefault(); this.runInstaller()}}>Upgrade</Link>)
        </React.Fragment>
      );
    }

    // otherwise, if advanced mode, display reinstall
    if (this.props.getGlobalState().advancedView) {
      return (
        <React.Fragment>
          &nbsp;(<Link to="" className="text-secondary" style={{ textDecoration: "underline" }} onClick={(e) => {e.preventDefault(); this.runInstaller()}}>Reinstall</Link>)
        </React.Fragment>
      );
    }
    return null;
  }

  displayStripePayment = () => {
    this.setState({
      displayDialog: dialogStripe
    })
  }

  displayAddLicenseKey = () => {
    this.setState({
      displayDialog: dialogAddLicenseKey
    })
  }

  displayManageLicenses = () => {
    this.setState({
      displayDialog: dialogManageLicenses
    })
  }

  logoutUser = () => {
    this.state.authContext.logoutUser();
  }

  renderVersion = () => {
    return (
      <React.Fragment>
        Core version <span className="text-secondary">{this.state.serviceVersion}</span>{this.getInstaller()}&nbsp;&nbsp;|&nbsp;&nbsp;
        Web version <span className="text-secondary">{this.getClientVersion()}</span>
      </React.Fragment>
    );
  }

  renderLicense = () => {
    if (this.state.licenseState === Consts.LICENSE.STATE.TRIAL) {
      return (
        <React.Fragment>
          License <span className="text-secondary">{this.state.licenseDetails}</span>&nbsp;&nbsp;|&nbsp;&nbsp;
          <Link to="" className="text-secondary" style={{ textDecoration: "underline" }} onClick={(e) => this.displayStripePayment()}>Purchase</Link>&nbsp;or&nbsp;
          <Link to="" className="text-secondary" style={{ textDecoration: "underline" }} onClick={(e) => this.displayAddLicenseKey()}>Assign this machine to an existing license key</Link>
        </React.Fragment>
      );
    } else {
      return (
        <React.Fragment>
          License <span className="text-secondary">{this.state.licenseDetails}</span>&nbsp;&nbsp;|&nbsp;&nbsp;
          Key <span className="text-secondary">{this.state.licenseKey}</span>
          &nbsp;&nbsp;|&nbsp;&nbsp;<Link to="" className="text-secondary" style={{ textDecoration: "underline" }} onClick={(e) => this.displayManageLicenses()}>Manage license</Link>
        </React.Fragment>
      );
    }
  }

  renderInstall = (authContext) => {
    // authenticated?
    if (!authContext.state.authenticated) {
      return (
        <div>
          <Card>
            <CardBody>
              <h4>ANVIL user not authenticated!</h4>
            </CardBody>
          </Card>
        </div>
      );
    }

    // fatal error?
    if (this.state.fatalError !== null) {
      return (
        <div>
          <Card>
            <CardBody>
              <h4>ANVIL fatal error!</h4>
            </CardBody>
          </Card>
        </div>
      );
    }

    // install check
    if (!authContext.state.verified) {
      // startcheck
      if (!this.verificationTimer) {
        console.log("Starting verification check...");
        this.verificationTimer = setInterval(() => {
          // check for connection
          authContext.checkAccountVerified().then((verified) => {
            if (verified) {
              clearInterval(this.verificationTimer);
            }
          });
        }, 2500);
      }

      return (
        <div>
          <h4>ANVIL account not verified</h4>
          <h5>You need to verify your ANVIL account before continuing. If you wish to continue without verification, <Link to="#" onClick={(e) => this.noVerification()}>click here</Link></h5>
          <Button color="success" size="lg" onClick={e => this.resendVerificationEmail(authContext)}>
            Resend verification email
          </Button>
        </div>
      );
    } else {
      return (
        <div>
          <h4>{this.state.manageMode === modeUpgrade ? "ANVIL upgrade required" : "ANVIL not installed"}</h4>
          <h5>{this.state.manageMode === modeUpgrade ? "You need to upgrade the ANVIL core before you can continue. Once upgraded, the ANVIL portal will connect directly to your local ANVIL service." : "You need to install ANVIL to get started. Once installed, the ANVIL portal will connect directly to your local ANVIL service."}</h5>
          <h5>{this.state.manageMode === modeUpgrade ? "Your existing rules and settings will remain untouched." : "All drives on your system will initialized with rules that allow full access, so you will not be prevented from accessing or modifying your files."}</h5>
          <Button color="success" size="lg" onClick={e => this.runInstaller(authContext)}>
            {this.state.manageMode === modeUpgrade ? "Upgrade ANVIL File Security" : "Install ANVIL File Security"}
          </Button>
          <Switch condition={this.state.installerMode}>
            <Case value={1}>
              <h5>Download and run the ANVIL connector</h5>
              <h5>Waiting to connector&nbsp;&nbsp;<span><ClipLoader sizeUnit={"px"} size={20} color={'#1e1e1e'} loading={this.state.installerMode === 1} /></span></h5>
            </Case>
            <Case value={2}>
              <h4>ANVIL installer connected, running version {this.state.installerVersion}</h4>
            </Case>
          </Switch>
        </div>
      );
    }
  }

  renderDrive = (drive) => {
    return (
      <Card body inverse style={{ backgroundColor: '#f4f5f7', borderColor: '#fff' }}>
        <CardBody>
          <DriveRules {...this.props} drive={drive} onStateValueChange={this.setStateValue} advancedView={false} />
        </CardBody>
      </Card>
    );
  }

  renderManageDriveList = () => {
    return (
      <React.Fragment>
        <Row>
          <Col xs={12} lg={10} xl={12}>
            <h4>The ANVIL service is installed and connected. These are the drives on your system, to protect a folder on any of these drives, select "Add protection".</h4>
          </Col>
        </Row>
        <Row>
          <Col xs={10}>
            {this.state.driveList.map((drive, i) => {
              if (drive.visible && (this.props.getGlobalState().driveFilter === "*" || this.props.getGlobalState().driveFilter === drive.mountName)) {
                return (
                  <React.Fragment key={i}>
                    {this.renderDrive(drive)}
                  </React.Fragment>
                );
              } else {
                return null;
              }
            })}
          </Col>
          <Col xs={2}>
            <Link to="#" onClick={(e) => { this.filterDrives("*") }}>
              <div className={this.props.getGlobalState().driveFilter === "*" ? "button-icon-detail drive-icon-detail-selected" : "button-icon-detail drive-icon-detail-normal"}>
                <i className="fad fa-hdd" />
                <p>All drives</p>
              </div>
            </Link>
            {this.state.driveList.map((drive, i) => {
              if (drive.visible) {
                return (
                  <React.Fragment key={i}>
                    <Link to="#" onClick={(e) => { this.filterDrives(drive.mountName)} }>
                      <div className={this.props.getGlobalState().driveFilter === drive.mountName ? "button-icon-detail drive-icon-detail-selected" : "button-icon-detail drive-icon-detail-normal"}>
                        <i className="far fa-hdd" />
                        <p>Drive {drive.mountName}</p>
                      </div>
                    </Link>
                  </React.Fragment>
                );
                } else {
                  return null;
                }
            })}
            <br/><br/>
            <Link to="#" onClick={(e) => { this.toggleAdvancedView() }}>
              <div className={this.props.getGlobalState().advancedView ? "button-icon-detail drive-icon-detail-selected" : "button-icon-detail drive-icon-detail-normal"}>
                <i className="fas fa-cogs" />
                <p>Advanced</p>
              </div>
            </Link>
            <Switch condition={this.props.getGlobalState().advancedView}>
              <Case value={true}>
                <Link to="#" onClick={(e) => { this.refreshView() }}>
                  <div className="button-icon-detail drive-icon-detail-normal">
                    <i className="fas fa-sync" />
                    <p>Refresh view</p>
                  </div>
                </Link>
                <Link to="#" onClick={(e) => { e.preventDefault(); this.launchCommandLine() }}>
                  <div className="button-icon-detail drive-icon-detail-normal">
                    <i className="fas fa-terminal" />
                    <p>Terminal</p>
                  </div>
                </Link>
                <Link to="#" onClick={(e) => { e.preventDefault(); this.launchLoggingConsole() }}>
                  <div className="button-icon-detail drive-icon-detail-normal">
                    <i className="fas fa fa-list-alt" />
                    <p>Live logging</p>
                  </div>
                </Link>
              </Case>
            </Switch>
          </Col>
        </Row>
        <Row>
          <Col>
            <p className="text-muted">
              <small>
                {this.renderVersion()}<br/>
                {this.renderLicense()}
              </small>
            </p>
          </Col>
        </Row>
      </React.Fragment>
    );
  }

  render() {
    if (this.state.redirectLogin) {
      return (
        <Redirect to={Consts.USER.LOGIN} />
      )
    }

    if (!this.state.portalBlocked) {
      return (
        <AuthenticationContext.Consumer>
          {authContext =>
            <React.Fragment>
              <Authentication onAuthenticate={this.onAuthenticated} onNotAuthenticated={this.onNotAuthenticated} />
              <Switch condition={this.state.displayDialog}>
                <Case value={dialogLicenseAgreement}>
                  <LicenseAgreement {...this.props} onClose={(agree) => this.closeLicenseAgreement(agree)} />
                </Case>
                <Case value={dialogInstaller}>
                  <Installer {...this.props} onClose={(installed) => this.closeInstaller(installed)} />
                </Case>
                <Case value={dialogGettingStarted}>
                  <GettingStarted {...this.props} systemDrive={this.systemDrive} onClose={(complete) => this.closeGettingStarted(complete)} onNewFolderApp={this.newFolderApp} />
                </Case>
                <Case value={dialogStripe}>
                  <Elements stripe={stripePromise}>
                    <ElementsConsumer>
                      {({stripe, elements}) => (
                        <StripePayment {...this.props} stripe={stripe} elements={elements} authContext={authContext} machineKey={this.machineKey} machineName={this.machineName} onClose={(paymentMade) => this.closePayment(paymentMade)} />
                      )}
                    </ElementsConsumer>
                  </Elements>
                </Case>
                <Case value={dialogAddLicenseKey}>
                  <AddLicenseKey {...this.props} licenseKey={this.state.licenseKey} machineKey={this.machineKey} machineName={this.machineName} onClose={(keyAdded) => this.closeAddLicenseKey(keyAdded)} />
                </Case>
                <Case value={dialogManageLicenses}>
                  <ManageLicenses {...this.props} licenseKey={this.state.licenseKey} machineKey={this.machineKey} machineName={this.machineName} onClose={(action) => this.closeManageLicense(action)} />
                </Case>
              </Switch>
              <div className="content-full-page">
                <div className="home">
                  <Row>
                    <Col xs={12}>
                      <Card>
                        <CardBody>
                          <Switch condition={this.state.manageMode}>
                            <Case value={modeInstall}>
                              {this.renderInstall(authContext)}
                            </Case>
                            <Case value={modeUpgrade}>
                              {this.renderInstall(authContext)}
                            </Case>
                            <Case value={modeNormal}>
                              {this.renderManageDriveList()}
                            </Case>
                            <Case value={modeServiceError}>
                            <div className="text-center" style={{ marginLeft: "25px", marginRight: "100px", marginTop: "50px", marginBottom: "50px" }}>
                                <h1>{this.state.serviceError}</h1>
                                {this.state.serviceError === "Authentication token is invalid." ?
                                  <h5 className="text-warning">It is possible that you have logged into the wrong account, or you session has expired. Try <Link to="#" onClick={(e) => this.logoutUser()}>logging out</Link> then back in again.</h5>
                                : null }
                              </div>
                            </Case>
                            <Default>
                              <div className="text-center" style={{ marginLeft: "25px", marginRight: "100px", marginTop: "50px", marginBottom: "50px" }}>
                                <h1>Connecting to service...</h1>
                              </div>
                            </Default>
                          </Switch>
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>
                </div>
              </div>
            </React.Fragment>
          }
        </AuthenticationContext.Consumer>
      );
    }
  }
}

export default Home;
