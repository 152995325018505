import React from "react";
import { Route, Switch } from "react-router-dom";
import PerfectScrollbar from "perfect-scrollbar";
import Header from "components/page/header";
import Footer from "components/page/footer";
import routes from "routes";
import Loadable from "react-loading-overlay";
import { Notifications, displayNotification, DialogAlerts, displayDialog } from "config/utilities"
import Sidebar from "components/admin/sidebar";
import FixedPlugin from "components/admin/fixed-plugin";

import logo from "assets/img/react-logo.png";

var ps;

class Admin extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      backgroundColor: "blue",
      sidebarOpened:
        document.documentElement.className.indexOf("nav-open") !== -1
    };
  }

  componentDidMount() {
    if (navigator.platform.indexOf("Win") > -1) {
      document.documentElement.className += " perfect-scrollbar-on";
      document.documentElement.classList.remove("perfect-scrollbar-off");
      ps = new PerfectScrollbar(this.refs.mainPanel, { suppressScrollX: true });
      let tables = document.querySelectorAll(".table-responsive");
      for (let i = 0; i < tables.length; i++) {
        ps = new PerfectScrollbar(tables[i]);
      }
    }
  }

  componentWillUnmount() {
    if (navigator.platform.indexOf("Win") > -1) {
      ps.destroy();
      document.documentElement.className += " perfect-scrollbar-off";
      document.documentElement.classList.remove("perfect-scrollbar-on");
    }
  }

  componentDidUpdate(e) {
    if (e.history.action === "PUSH") {
      if (navigator.platform.indexOf("Win") > -1) {
        let tables = document.querySelectorAll(".table-responsive");
        for (let i = 0; i < tables.length; i++) {
          ps = new PerfectScrollbar(tables[i]);
        }
      }
      document.documentElement.scrollTop = 0;
      document.scrollingElement.scrollTop = 0;
      this.refs.mainPanel.scrollTop = 0;
    }
  }

  // this function opens and closes the sidebar on small devices
  toggleSidebar = () => {
    document.documentElement.classList.toggle("nav-open");
    this.setState({ sidebarOpened: !this.state.sidebarOpened });
  };

  setCleanPage = () => {
    this.setLoader(false);
  }

  setLoader = (display) => {
    this.setState({
      loader: display
    })
  }

  displayNotification = (type, message, autoClose) => {
    displayNotification(this.refs.notificationAlert, type, message, autoClose);
  }

  displayDialog = (type, title, message, onClose) => {
    displayDialog(this.refs.dialogAlert, type, title, message, onClose);
  }

  getRoutes = (routes) => {

    return routes.map((prop, key) => {
      if (prop.layout === "/") {
        return (
          <Route path={prop.path} render={(props) => <prop.component {...props} setCleanPage={this.setCleanPage} setLoader={this.setLoader} displayNotification={this.displayNotification} displayDialog={this.displayDialog} />} key={key} />
        );
      }

      if (prop.layout === "/admin") {
        return (
          <Route path={prop.layout + prop.path} component={prop.component} key={key} />
        );
      }

      return null;
    });
  };

  handleBgClick = color => {
    this.setState({ backgroundColor: color });
  };

  getBrandText = path => {
    for (let i = 0; i < routes.length; i++) {
      if (this.props.location.pathname.indexOf(routes[i].layout + routes[i].path) !== -1) {
        return routes[i].name;
      }
    }
    return "Division-M";
  };

  render() {
    return (
      <React.Fragment>
        <Loadable active={this.state.loader} spinner text="Working, please wait...">
          <div className="react-notification-alert-container">
            <Notifications ref="notificationAlert" />
          </div>
          <DialogAlerts ref="dialogAlert" />
          <div className="wrapper">
            <Sidebar {...this.props} routes={routes} bgColor={this.state.backgroundColor} logo={{ outterLink: "https://www.creative-tim.com/", text: "Creative Tim", imgSrc: logo }} toggleSidebar={this.toggleSidebar} />
            <div className="main-panel" ref="mainPanel" data={this.state.backgroundColor} >
              <Header {...this.props}
                brandText={this.getBrandText(this.props.location.pathname)}
                toggleSidebar={this.toggleSidebar}
                sidebarOpened={this.state.sidebarOpened}
                displayNotification={this.displayNotification}
                displayDialog={this.displayDialog}
                />
              <Switch>{this.getRoutes(routes)}</Switch>
              <Footer
                fluid
                displayNotification={this.displayNotification}
                displayDialog={this.displayDialog}
                />
            </div>
          </div>
          <FixedPlugin bgColor={this.state.backgroundColor} handleBgClick={this.handleBgClick} />
        </Loadable>
      </React.Fragment>
    );
  }
}

export default Admin;
