import React from "react";
import ReactDOM from "react-dom";
import { createBrowserHistory } from "history";
import { Router, Route, Switch } from "react-router-dom";
import { AuthenticationContextProvider } from "context/authentication";
import FullPageLayout from "layouts/full-page.jsx";
import AdminLayout from "layouts/admin.jsx";

import "assets/scss/black-dashboard-react.scss";
import "assets/css/custom.css";
import "assets/css/nucleo-icons.css";

const hist = createBrowserHistory();

ReactDOM.render(
  <Router onUpdate={() => window.scrollTo(0, 0)} history={hist}>
    <AuthenticationContextProvider>
      <Switch>
        <Route path="/admin" render={props => <AdminLayout {...props} />} />
        <Route path="/" render={props => <FullPageLayout {...props} />} />
        <Route path="/upgrade" render={props => <FullPageLayout {...props} />} />
      </Switch>
    </AuthenticationContextProvider>
  </Router>,
  document.getElementById("root")
);
