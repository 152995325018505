import React from "react";
import { Route, Switch as SwitchRoute } from "react-router-dom";
import PerfectScrollbar from "perfect-scrollbar";
import Header from "components/page/header.jsx";
import Footer from "components/page/footer.jsx";
import routes from "routes.js";
import Loadable from "react-loading-overlay";
import { Notifications, displayNotification, DialogAlerts, displayDialog, getWebAppValue } from "config/utilities"
import { Authentication } from "context/authentication";
import { Firestore } from "config/firebase";
import { Consts }from "config/consts.js"
import Switch, { Case } from "react-switch-case";

import canary from "assets/img/canary_light_100x100.png";
import anvil from "assets/img/anvil_light_100x100.png";

var ps;

class Admin extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      authContext: null,
      backgroundColor: "blue",
      sidebarOpened: false,
      loader: false,
      globals:{
        advancedView: false,
        driveFilter: '*',
        runningProcessSelect: false,
      },
      globalsSessionOnly: {
        license: {
          type: 0,
          state: 0,
          validated: false,
          key: ""
        },
        upgrade: this.props.location.pathname.toLowerCase() === "/upgrade"
      }
    }
  }

  componentDidMount() {
    if (navigator.platform.indexOf("Win") > -1) {
      document.documentElement.className += " perfect-scrollbar-on";
      document.documentElement.classList.remove("perfect-scrollbar-off");
      ps = new PerfectScrollbar(this.refs.mainPanel, { suppressScrollX: true });
      let tables = document.querySelectorAll(".table-responsive");
      for (let i = 0; i < tables.length; i++) {
        ps = new PerfectScrollbar(tables[i]);
      }
    }
  }

  componentWillUnmount() {
    if (navigator.platform.indexOf("Win") > -1) {
      ps.destroy();
      document.documentElement.className += " perfect-scrollbar-off";
      document.documentElement.classList.remove("perfect-scrollbar-on");
    }
  }

  componentDidUpdate(e) {
    if (e.history.action === "PUSH") {
      if (navigator.platform.indexOf("Win") > -1) {
        let tables = document.querySelectorAll(".table-responsive");
        for (let i = 0; i < tables.length; i++) {
          ps = new PerfectScrollbar(tables[i]);
        }
      }
      document.documentElement.scrollTop = 0;
      document.scrollingElement.scrollTop = 0;
      this.refs.mainPanel.scrollTop = 0;
    }
  }

  onAuthenticated = (authContext) => {
    this.setState({
      authContext: authContext
    });
    // load settings
    Firestore.collection("users").doc(authContext.state.uid).get().then((doc) => {
      let globals = doc.data().interface;
      if (globals)
        this.setState({
          globals: globals
        })
    });
  }

  // this function opens and closes the sidebar on small devices
  toggleSidebar = () => {
    document.documentElement.classList.toggle("nav-open");
    this.setState({ sidebarOpened: !this.state.sidebarOpened });
  };

  getAuthContext = () => {
    return this.state.authContext;
  }

  setCleanPage = () => {
    this.setLoader(false);
  }

  setLoader = (display) => {
    this.setState({
      loader: display
    })
  }

  displayNotification = (type, message, autoClose) => {
    displayNotification(this.refs.notificationAlert, type, message, autoClose);
  }

  displayDialog = (type, title, message, onConfirmClose, onCancel) => {
    displayDialog(this.refs.dialogAlert, type, title, message, onConfirmClose, onCancel);
  }

  setGlobalState = (name, value, doNotPersist) => {
    if (!doNotPersist) {
      let globals = { ...this.state.globals, [name]: value }
      this.setState({
        globals: globals
      }, () => {
        // save to users account
          if (this.state.authContext) {
            Firestore.collection("users").doc(this.state.authContext.state.uid).set({
              interface: this.state.globals
            }, { merge: true });
          }
      });
    } else {
      let globalsSessionOnly = { ...this.state.globalsSessionOnly, [name]: value }
      this.setState({
        globalsSessionOnly: globalsSessionOnly
      })
    }
  }

  getGlobalState = () => {
    return {...this.state.globals, ...this.state.globalsSessionOnly}
  }

  getRoutes = (routes) => {
    return routes.map((prop, key) => {
      if (prop.layout === "/") {
        return (
          <Route path={prop.path} render={(props) => <prop.component {...props} getAuthContext={this.getAuthContext} setCleanPage={this.setCleanPage} setLoader={this.setLoader} setGlobalState={this.setGlobalState} getGlobalState={this.getGlobalState} displayNotification={this.displayNotification} displayDialog={this.displayDialog} />} key={key} />
        );
      }

      if (prop.layout === "/admin") {
        return (
          <Route path={prop.layout + prop.path} component={prop.component} key={key} />
        );
      }

      return null;
    });
  };

  handleBgClick = color => {
    this.setState({ backgroundColor: color });
  };

  getBrandText = path => {
    for (let i = 0; i < routes.length; i++) {
      if (this.props.location.pathname.indexOf(routes[i].layout + routes[i].path) !== -1) {
        return routes[i].name;
      }
    }
    if (this.state.authContext && this.state.authContext.state.authenticated === true) {
      return (
        <Switch condition={Consts.SYSTEM.PRODUCT_ID}>
          <Case value="anvil">
            <img src={anvil} alt="ANVIL" height="50px" width="50px" />&nbsp;<span>{getWebAppValue(Consts.WEB_APP_VALUE.NAME)}&nbsp;</span><span style={{ color: "#fb6340" }}>File Security</span>
          {!Consts.SYSTEM.RELEASE ? " [DEV MODE]" : null }
          </Case>
          <Case value="tcc">
            <img src={canary} alt="Canary" height="50px" width="50px" />&nbsp;<span style={{ color: "#fb6340", paddingTop: "100px" }}>{getWebAppValue(Consts.WEB_APP_VALUE.NAME)}</span>
            {!Consts.SYSTEM.RELEASE ? " [DEV MODE]" : null }
          </Case>
        </Switch>
      );
    } else {
      return (
      <Switch condition={Consts.SYSTEM.PRODUCT_ID}>
        <Case value="anvil">
          <img src={anvil} alt="Anvil" height="50px" width="50px" />
        </Case>
        <Case value="tcc">
          <img src={canary} alt="Canary" height="50px" width="50px" />
        </Case>
      </Switch>
      );
    }
  };

  render() {
    return (
      <Loadable active={this.state.loader} spinner text="Working, please wait...">
        <Authentication onAuthenticate={this.onAuthenticated} allowRedirect={true} />
        <div className="react-notification-alert-container">
          <Notifications ref="notificationAlert" />
        </div>
        <DialogAlerts ref="dialogAlert" />
        <div className="wrapper">
          <div className="main-panel" ref="mainPanel" data={this.state.backgroundColor} >
            <Header {...this.props}
              brandText={this.getBrandText(this.props.location.pathname)}
              getAuthContext={this.getAuthContext}
              setCleanPage={this.setCleanPage}
              setLoader={this.setLoader}
              setGlobalState={this.setGlobalState}
              getGlobalState={this.getGlobalState}
              displayNotification={this.displayNotification}
              displayDialog={this.displayDialog}
              />
            <SwitchRoute>{this.getRoutes(routes)}</SwitchRoute>
            {this.props.location.pathname.indexOf("/user/") === -1 ? null : (
              <Footer
                fluid
                getAuthContext={this.getAuthContext}
                setCleanPage={this.setCleanPage}
                setLoader={this.setLoader}
                setGlobalState={this.setGlobalState}
                getGlobalState={this.getGlobalState}
                displayNotification={this.displayNotification}
                displayDialog={this.displayDialog}
                />
            )}
          </div>
        </div>
      </Loadable>
    );
  }
}

export default Admin;